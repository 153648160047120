import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Footer from '@sections/Footer';

class Calendly extends React.Component {

  constructor(props, state) {
    super(props);
    this.state = {
      isCalendlyLoaded: false
    }
  }

  UNSAFE_componentWillMount() {
    if (typeof window === 'undefined' || !window.document) {
      return;
    }
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
    script.onload = () => {
      setTimeout(() => {
        this.setState({
          isCalendlyLoaded: true
        })
      }, 1000);
    };
  }

  componentWillUnmount() {
    // whatever you need to cleanup the widgets code
  }

  render() {
    const { isCalendlyLoaded } = this.state;

    return (
      <div style={{ marginTop: -30, textAlign: 'center' }}>
        <div id="schedule_form">
          {!isCalendlyLoaded ? <div style={{marginTop: 150}}><span style={{fontSize: 25}}>Loading ...</span></div> : null}
          <div 
            className="calendly-inline-widget"
            data-url="https://calendly.com/admissionrush"
            style={{ minWidth: '320px', height: '680px' }}
          />
        </div>
      </div>
    )
  }
}

const IndexPage = () => {
  
  return (
    <Layout>
      <Navbar />
      <div style={{margin: '0 auto', marginTop: 110, width: '100%', textAlign: 'center'}}>
        <h2>Happy To Connect</h2>
        <div style={{marginTop: 20, fontSize: 18}}>
          Schedule a FREE 1:1 profile evaluation session now
        </div>
        <Calendly />
      </div>
      <Footer />
    </Layout>
  )
}

export default IndexPage;
